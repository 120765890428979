<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    404
  </div>
</template>

<script step lang="ts">

</script>
